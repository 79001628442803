<template>
	<div class='a-card'>
		<div class='c-card-wrap'>
			<div class='c-card-container'>
				<div class='c-card-image'>
					<img :src="`https://tcc.ua/storage/files/images/hotels/578824/1.jpg`">
				</div>
				<div class='c-card-content'>
					<p class='c-card-title'>Компакт 2-3 дверей</p>
					<p class='c-card-sub-title'>Fiat 500 або аналогічний по класу</p>

					<a-card-list
						:items="[{icon: 'users', name: '4'}, {icon: 'door', name: '3'}]"
						isLarge
					/>
					<a-card-list
						:items="[{icon: 'transmission', name: 'Механічна'}, {icon: 'show', name: 'Кондиціонер'}]"
					/>

					<div class='c-card-info'>
						<p class='a-text-with-icon green--text'>
							<v-icon icon='speed' size='13' />
							<span>Необмежений пробіг</span>
						</p>
						<p class='a-text-with-icon green--text'>
							<v-icon icon='refund' size='13' />
							<span>Безкоштовне скасування</span>
						</p>
					</div>

					<div class='c-card-footer'>
						<div class='tablet-show' style='padding-right: 12px'>
							<v-button block xxsmall outline color='green' class='tablet-show a-card-mobile-btn' :class='{"app-btn-active": comparison}'>
								<v-icon :icon='comparison ? "delete" : "graph"' size='14' />
								<span>{{comparison ? $t('account.buttons.remove') : $t('account.comparison.for_comparison')}}</span>
							</v-button>
						</div>
					</div>
				</div>
			</div>
			<div class='c-card-action'>
				<div class='c-card-action-total'>
					<p class='a-card-total'>4 535 грн</p>
					<p class='a-card-text'>Ціна загалом за 7 днів</p>
				</div>
				<div class='c-card-action-group-buttons'>
					<v-button block xxsmall color='green white--text'
						@click.stop='$emit("open-details")'
					>{{$t("account.buttons.details")}}</v-button>
					<v-button block xxsmall outline color='green' class='tablet-hide' :class='{"app-btn-active": comparison}'>
						<v-icon :icon='comparison ? "delete" : "graph"' size='14' class='desktop-show' />
						<span class='desktop-show'>{{comparison ? $t('account.buttons.remove') : $t('account.comparison.for_comparison')}}</span>
						<v-icon icon='delete' size='14' class='desktop-hide' v-if='comparison' />
						<span class='desktop-hide'>{{comparison ? $t('account.comparison.remove_from_comparison') : $t('account.comparison.add_to_compare')}}</span>
					</v-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ACardList from '@/views/account/search/cars/card/list';

	export default {
		props: ["item", "comparison"],
		components: {
			ACardList
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.c-card {
		&-wrap {
			@include minw($grid-breakpoints-sm) {
				display: flex;
				align-items: stretch;
			}
		}
		&-container {
			flex: 1 1 100%;
			@include minw($grid-breakpoints-xs) {
				display: flex;
				align-items: center;
			}
		}
		&-image {
			flex-shrink: 0;
			width: 100%;
			height: 245px;
			padding: 20px 15px 0;
			@include minw($grid-breakpoints-xs) {
				padding: 0;
				width: 285px * .7;
				height: 201px * .7;
				margin: 20px * .7;
			}
			@include minw($grid-breakpoints-md) {
				width: 285px * .8;
				height: 201px * .8;
				margin: 20px * .8;
			}
			@include minw($grid-breakpoints-lg) {
				width: 285px;
				height: 201px;
				margin: 20px;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		&-content {
			padding: 15px;
			flex: 1 1 100%;
			margin: 0;
			display: flex;
			flex-direction: column;
			@include minw($grid-breakpoints-xs) {
				padding: 0;
				margin: 25px * .8 30px * .8 35px * .8 15px * .8;
			}
			@include minw($grid-breakpoints-lg) {
				margin: 25px 30px 35px 15px;
			}
		}
		&-title {
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 5px;
		}
		&-sub-title {
			font-size: 12px;
			font-weight: 400;
			color: rgba($black, .6);
			margin-bottom: 5px;
		}
		&-info {
			margin: 10px 0 25px;
			@include minw($grid-breakpoints-sm) {
				margin: 15px 0;
			}
		}
		&-action {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 15px;
			border-top: 1px solid rgba($black, .1);
			@include minw($grid-breakpoints-sm) {
				flex-direction: column;
				align-items: stretch;
				justify-content: flex-end;
				border-top: none;
				border-left: 1px solid rgba($black, .1);
				padding: 20px;
				flex: 0 0 300px * .7;
			}
			@include minw($grid-breakpoints-md) {
				flex: 0 0 300px * .8;
			}
			@include minw($grid-breakpoints-lg) {
				flex: 0 0 300px;
			}
			&-total {
				display: grid;
				@include minw($grid-breakpoints-sm) {
					text-align: center;
					margin-bottom: 30px * .8;
				}
				@include minw($grid-breakpoints-lg) {
					margin-bottom: 30px;
				}
			}
			&-group-buttons {
				width: 100%;
				@include maxw($grid-breakpoints-sm - 1) {
					max-width: 125px;
				}
			}
			.app-btn {
				font-size: 15px;
				padding-left: 12px;
				padding-right: 12px;
				@include minw($grid-breakpoints-sm) {
					padding: 0 4px;
					&:not(:last-child) {
						margin-bottom: 10px;
					}
				}
				@include minw($grid-breakpoints-lg) {
					font-size: 16px;
				}
				@include maxw($grid-breakpoints-sm - 1) {
					width: auto !important;
					min-width: 125px !important;
				}
			}
		}
		&-button {
			&-compare {
				font-weight: 400;
			}
		}
		&-footer {
			display: flex;
			align-items: center;
			@include maxw($grid-breakpoints-sm - 1) {
				flex-wrap: wrap;
			}
		}
		&-mobile-btn {
			height: 40px;
			font-size: 14px;
			min-width: 125px;
		}
	}
</style>
