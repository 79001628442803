<template>
	<div class='a-cars-specification'>
		<p class='a-cars-specification-title'>Компакт 2-3 дверей</p>
		<p class='a-cars-specification-sub-title'>Fiat 500 або аналогічний по класу</p>

		<div class='a-cars-specification-container'>
			<div class='a-cars-specification-image' v-if='!hideImage'>
				<img :src="`https://tcc.ua/storage/files/images/hotels/578824/1.jpg`">
			</div>
			<div class='a-cars-specification-content' :style='hideImage ? "margin-left: 0" : null'>
				<a-card-list
					:items="[{icon: 'users', name: '4'}, {icon: 'door', name: '3'}]"
					isLarge
				/>
				<a-card-list
					:items="[{icon: 'transmission', name: 'Механічна'}, {icon: 'show', name: 'Кондиціонер'}]"
				/>

				<slot />
			</div>
		</div>
	</div>
</template>

<script>
	import ACardList from '@/views/account/search/cars/card/list';

	export default {
		props: ['hideImage'],
		components: {
			ACardList
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-cars-specification {
		&-container {
			padding: 20px 0;
			display: flex;
			align-items: center;
		}
		&-image {
			flex-shrink: 0;
			width: 120px;
			height: 75px;
			overflow: hidden;
			border-radius: 5px;
			@include minw($grid-breakpoints-xxs) {
				width: 140px;
			}
			@include minw( $grid-breakpoints-xs ) {
				width: 180px;
				height: 105px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				height: 105px;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		&-content {
			flex: 1 1 100%;
			margin-left: 20px;
			display: flex;
			flex-direction: column;
			@include minw($grid-breakpoints-xxs) {
				margin-left: 30px;
			}
			@include minw($grid-breakpoints-xs) {
				margin-left: 50px;
			}
		}
		&-title {
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 5px;
		}
		&-sub-title {
			font-size: 12px;
			font-weight: 400;
			color: rgba($black, .6);
			margin-bottom: 5px;
		}
	}
</style>
